.footer-sout-links {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  font-size: 10px;
  margin-top: -10px;

  &__item {
    padding-left: 5px;
    padding-right: 5px;
  }
  &__link {
    font-size: 10px;
    color: #DDDDDD;
  }
}