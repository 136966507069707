.language {
  position: relative;
  background: #333333;
  height: 100%;
  /*lg*/
  /*inner - 980px + 30px*2 */
  /*md*/
  /*sm*/
}
@media screen and (max-width: 1240px) {
  .language {
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  .language {
    background: transparent;
    position: relative;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1040px) {
  .language {
    position: relative;
  }
}
@media screen and (max-width: 960px) {
  .language {
    position: relative;
  }
}
@media screen and (max-width: 670px) {
  .language {
    position: relative;
    font-size: 18px;
  }
}
.language__lang {
  font-size: 18px;
  font-weight: 900;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  width: 112px;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media screen and (max-width: 1240px) {
  .language__lang {
    width: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .language__lang {
    padding: 12px 20px 12px 29px;
    font-size: 18px;
    line-height: 1.2;
    color: #F2994A;
    width: 100%;
    justify-content: flex-start;
  }
}
.language__lang--active {
  color: #F2994A;
}
.language__lang-current {
  position: relative;
}
@media screen and (max-width: 1200px) {
  .language__lang-current {
    width: 100%;
  }
}
.language__lang-current:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../images/icons/caret_down.svg) no-repeat center;
  background-size: contain;
}
@media screen and (max-width: 1200px) {
  .language__lang-current:after {
    background-image: url(../images/icons/caret_down-mob.svg);
    width: 11px;
    bottom: 0;
    top: 0;
    left: auto;
    transition: all 0.5s;
  }
}
.language__choise-block {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  background: #333333;
  z-index: 10;
  padding-bottom: 10px;
}
.language__choise-block--active {
  display: block;
}
@media screen and (max-width: 1200px) {
  .language__choise-block {
    position: relative;
    left: auto;
    background: transparent;
    padding-bottom: 0;
  }
}
.language__choise-item {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 900;
  color: #ffffff;
  transition: all 0.5s;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .language__choise-item {
    color: #333333;
    justify-content: flex-start;
    padding-left: 45px;
    font-weight: 400;
  }
}
.language__choise-item--active {
  color: #F7931D;
}
@media screen and (max-width: 1200px) {
  .language__choise-item--active {
    color: #F7931D;
  }
}
@media screen and (min-width: 961px) {
  .language__choise-item:hover {
    background-color: #F7931D;
    color: #ffffff;
  }
}
.language__lang--active .language__lang-current:after {
  background-image: url(../images/icons/caret_down-2.svg);
}
@media screen and (max-width: 1200px) {
  .language__lang--active .language__lang-current:after {
    background-image: url(../images/icons/caret_down-mob.svg);
    transform: rotate(-180deg);
  }
}
.single-new-new {
  padding: 80px 0 110px;
}
@media screen and (max-width: 960px) {
  .single-new-new {
    padding: 75px 0 65px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new {
    padding: 55px 0 50px;
  }
}
.single-new-new__date {
  position: absolute;
  left: 0;
  bottom: 53px;
  font-size: 14px;
}
@media screen and (max-width: 960px) {
  .single-new-new__date {
    bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__date {
    top: 41px;
    bottom: auto;
  }
}
.single-new-new__btns-block {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.single-new-new__btn {
  margin: 0 10px 10px;
  padding: 11px 20px;
}
@media screen and (max-width: 670px) {
  .single-new-new__btn {
    width: 100%;
  }
}
.single-new-new__btn--back {
  min-width: 142px;
}
.single-new-new__btn--all-news {
  min-width: 178px;
}
.single-new-new__text-block {
  padding-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .single-new-new__text-block {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__text-block {
    padding-bottom: 30px;
  }
}
.single-new-new__text-block h2,
.single-new-new__text-block h3 {
  padding-bottom: 23px;
}
.single-new-new__text-block h2 {
  font-size: 41px;
}
@media screen and (max-width: 960px) {
  .single-new-new__text-block h2 {
    font-size: 33px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__text-block h2 {
    font-size: 30px;
  }
}
.single-new-new__text-block p {
  padding-bottom: 23px;
  font-size: 20px;
}
.single-new-new__text-block ul {
  list-style: none;
  font-size: 20px;
  padding-bottom: 23px;
}
.single-new-new__text-block li {
  margin-bottom: 2px;
  position: relative;
  padding-left: 20px;
}
.single-new-new__text-block li:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 8px;
  left: 0;
}
.single-new-new__text-block img {
  margin-bottom: 23px;
}
.single-new-new__photo-left {
  padding-right: 20px;
}
@media screen and (max-width: 670px) {
  .single-new-new__photo-left {
    padding-right: 0;
    width: 100%;
  }
}
.single-new-new__columns2 {
  columns: 2 auto;
  column-gap: 20px;
}
@media screen and (max-width: 670px) {
  .single-new-new__columns2 {
    columns: auto;
  }
}
.single-new-new__promo--photo {
  max-width: 880px;
  margin: auto;
  padding-bottom: 37px;
}
@media screen and (max-width: 960px) {
  .single-new-new__promo--photo {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__promo--photo {
    padding-bottom: 20px;
  }
}
.single-new-new__promo--photo p {
  font-size: 14px;
  margin-top: -13px;
}
.single-new-new__content--photo {
  padding-top: 37px;
  padding-bottom: 37px;
}
@media screen and (max-width: 960px) {
  .single-new-new__content--photo {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__content--photo {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.single-new-new__content--photo p {
  font-size: 14px;
  margin-top: -13px;
}
.single-new-new__content--table {
  padding-top: 37px;
  padding-bottom: 37px;
}
@media screen and (max-width: 960px) {
  .single-new-new__content--table {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
@media screen and (max-width: 670px) {
  .single-new-new__content--table {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.single-new-new__content--table table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #333;
  margin-bottom: 17px;
}
.single-new-new__content--table table th,
.single-new-new__content--table table td {
  padding: 10px;
  border: 1px solid #333;
  font-size: 20px;
}
@media screen and (max-width: 670px) {
  .single-new-new__content--table table th,
  .single-new-new__content--table table td {
    font-size: 14px;
  }
}
.single-new-new__content--table table th {
  font-weight: 700;
  background-color: #f4f4f4;
  text-align: left;
}
.single-new-new__content--table table + p {
  font-size: 14px !important;
}
.single-new-new__text-block--text-page {
  padding-bottom: 0;
}
.cultural-new {
  padding: 120px 0 0;
}
@media screen and (max-width: 960px) {
  .cultural-new {
    padding: 60px 0 0;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new {
    padding: 60px 0 0;
  }
}
.cultural-new__block {
  padding-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .cultural-new__block {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__block {
    padding-bottom: 40px;
  }
}
.cultural-new__inner {
  max-width: 1000px;
}
@media screen and (max-width: 1200px) {
  .cultural-new__inner {
    max-width: 1018px;
  }
}
.cultural-new__row {
  margin-left: -30px;
  margin-right: -30px;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .cultural-new__row {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.cultural-new__row--start {
  align-content: flex-start;
  align-items: flex-start;
}
.cultural-new__col {
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 960px) {
  .cultural-new__col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.cultural-new__item {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .cultural-new__item {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__item {
    margin-bottom: 20px;
  }
}
.cultural-new__item--text {
  max-width: 430px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
@media screen and (max-width: 960px) {
  .cultural-new__item--text {
    font-size: 16px;
  }
}
.cultural-new__item--text-big {
  font-size: 24px;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .cultural-new__item--text-big {
    font-size: 20px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__item--text-big {
    font-size: 16px;
    line-height: 1.3;
  }
}
.cultural-new__item--text-big-more {
  font-size: 26px;
  line-height: 1.4;
}
@media screen and (max-width: 960px) {
  .cultural-new__item--text-big-more {
    font-size: 23px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__item--text-big-more {
    font-size: 20px;
    line-height: 1.3;
  }
}
.cultural-new__item--text p {
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .cultural-new__item--text p {
    padding-bottom: 15px;
  }
}
.cultural-new__item--text p:last-child {
  padding-bottom: 0;
}
.cultural-new__block-title {
  padding-bottom: 60px;
  max-width: 700px;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .cultural-new__block-title {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 670px) {
  .cultural-new__block-title {
    padding-bottom: 30px;
  }
}
.cultural-new__subtitle {
  font-weight: 700;
  text-align: center;
}
.cultural-new__row--reverse .cultural-new__col--photo {
  order: 1;
}
@media screen and (max-width: 670px) {
  .cultural-new__row--reverse .cultural-new__col--photo {
    order: 0;
  }
}
.promo-slider--cultural {
  padding-bottom: 100px;
}
@media screen and (max-width: 960px) {
  .promo-slider--cultural {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider--cultural {
    padding-bottom: 50px;
  }
}
.history-slider__dots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 700;
  margin: 0 auto;
}
@media screen and (max-width: 1040px) {
  .history-slider__dots {
    margin: 0 39px;
  }
}
@media screen and (max-width: 670px) {
  .history-slider__dots {
    margin: 0 30px;
  }
}
.history-slider__dots .slick-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  list-style: none;
}
.history-slider__dots .slick-dots:before {
  content: '';
  display: block;
  width: 100%;
  height: 8px;
  background: #9F9F9F;
  border-radius: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.history-slider__dots .slick-dots li {
  line-height: 0;
  font-size: 0;
  flex-grow: 1;
  position: relative;
  z-index: 1;
}
.history-slider__dots .slick-dots button {
  font-size: 0;
  height: 8px;
  position: relative;
  opacity: 1;
  width: 100%;
}
.history-slider__dots .slick-dots button:before {
  content: '';
  display: block;
  width: 100%;
  height: 8px;
  background: #9F9F9F;
  border-radius: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.history-slider__dots .slick-dots button:hover:before {
  height: 14px;
  top: -3px;
  background: #F7931D;
}
.history-slider__dots .slick-dots .slick-active button {
  height: 8px;
  border-radius: 15px;
  background: #F7931D;
  opacity: 1;
}
.history-slider__dots .slick-dots .slick-active button:before {
  background: #F7931D;
}
.history-slider__arrow {
  background: transparent;
  width: 50%;
  height: 100%;
}
.history-slider__arrow--left {
  left: 0;
}
.history-slider__arrow--right {
  right: 0;
}
.contacts-form-block__title-block {
  padding-bottom: 60px;
  width: 100%;
}
@media screen and (max-width: 670px) {
  .contacts-form-block__title-block {
    padding-bottom: 30px;
  }
}
.contacts-form-block__title {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .contacts-form-block__title {
    font-size: 21px;
    text-align: left;
  }
}
.our-clients {
  padding: 70px 120px 120px 20px;
}
@media screen and (max-width: 1200px) {
  .our-clients {
    padding-right: 20px;
  }
}
@media screen and (max-width: 960px) {
  .our-clients {
    padding-top: 0;
    padding-bottom: 80px;
  }
}
.our-clients__title {
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  padding-bottom: 60px;
}
@media screen and (max-width: 670px) {
  .our-clients__title {
    font-size: 30px;
    padding-bottom: 50px;
  }
}
.our-clients__for-slider {
  padding-bottom: 60px;
}
@media screen and (max-width: 670px) {
  .our-clients__for-slider {
    padding-bottom: 50px;
  }
}
.our-clients__btns-block {
  text-align: center;
}
.our-clients__btn {
  min-width: 201px;
  padding-left: 20px;
  padding-right: 20px;
}
.our-clients .slick-track {
  display: flex;
  align-content: center;
  align-items: center;
}
.our-clients__item {
  text-align: center;
}
.our-clients__link-item {
  display: inline-block;
  margin: auto;
  webkit-filter: grayscale(1);
  filter: grayscale(1) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  opacity: 1;
}
.our-clients__link-item:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.our-clients__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 21px;
  height: 36px;
  z-index: 5;
  background: url(../images/icons/slider-arrow-white.svg) no-repeat center;
  background-size: contain;
}
.our-clients__arrow--left {
  left: -40px;
}
@media screen and (max-width: 670px) {
  .our-clients__arrow--left {
    left: 0px;
  }
}
.our-clients__arrow--right {
  right: -40px;
  transform: rotate(180deg);
}
@media screen and (max-width: 670px) {
  .our-clients__arrow--right {
    right: 0px;
  }
}
.reviews-new {
  padding: 80px 0 100px;
}
@media screen and (max-width: 960px) {
  .reviews-new {
    padding: 40px 0 70px;
  }
}
@media screen and (max-width: 670px) {
  .reviews-new {
    padding: 30px 0 70px;
  }
}
.reviews-new__item {
  background: #F4F4F4;
  margin-bottom: 20px;
  padding: 40px;
}
.reviews-new__item-header {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.reviews-new__item-photo-block {
  width: 91px;
  position: relative;
}
.reviews-new__item-photo-block:after {
  content: '';
  width: 34px;
  height: 27px;
  display: block;
  background: url(../images/icons/bq-icon.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  right: -7px;
  top: -6px;
}
.reviews-new__item-photo {
  border-radius: 50%;
}
.reviews-new__item-contact-block {
  width: calc(100% - 91px);
  padding-left: 23px;
}
.reviews-new__item-name {
  font-size: 14px;
  font-weight: 700;
}
.reviews-new__item-position {
  font-size: 13px;
}
@media screen and (max-width: 960px) {
  .reviews-new__item-position {
    font-size: 14px;
  }
}
.reviews-new__text-block {
  font-size: 20px;
}
@media screen and (max-width: 960px) {
  .reviews-new__text-block {
    font-size: 19px;
  }
}
@media screen and (max-width: 670px) {
  .reviews-new__text-block {
    font-size: 20px;
  }
}
.promo__text-block:before {
  z-index: 3;
}
.promo__title,
.promo__text {
  z-index: 5;
}
.promo__picture {
  display: none;
}
.promo__picture:first-child {
  display: block;
}
.footer-sout-links {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  font-size: 10px;
  margin-top: -10px;
}
.footer-sout-links__item {
  padding-left: 5px;
  padding-right: 5px;
}
.footer-sout-links__link {
  font-size: 10px;
  color: #DDDDDD;
}
