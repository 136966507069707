.cultural-new {
    padding: 120px 0 0;

    @media screen and (max-width: 960px) {
        padding: 60px 0 0;
    }
    @media screen and (max-width: 670px) {
        padding: 60px 0 0;
    }

    &__block {
        padding-bottom: 60px;

        @media screen and (max-width: 960px) {
            padding-bottom: 20px;
        }
        @media screen and (max-width: 670px) {
            padding-bottom: 40px;
        }
    }

    &__inner {
        max-width: 1000px; //940+60

        @media screen and (max-width: 1200px) {
            max-width: 1018px; //940+39*2
        }

    }
    

    &__row {
        margin-left: -30px;
        margin-right: -30px;
        align-content: center;
        align-items: center;

        @media screen and (max-width: 960px) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @media screen and (max-width: 670px) {
            margin-left: -10px;
            margin-right: -10px;
        }

        &--start {
            align-content: flex-start;
            align-items: flex-start;
        }
    }
    &__col {
        padding-left: 30px;
        padding-right: 30px;

        @media screen and (max-width: 960px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media screen and (max-width: 670px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__item {
        margin-bottom: 60px;

        @media screen and (max-width: 960px) {
            margin-bottom: 40px;
        }
        @media screen and (max-width: 670px) {
            margin-bottom: 20px;
        }
    }
    &__item--photo {

    }
    &__item--text {
        max-width: 430px;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0.01em;

        @media screen and (max-width: 960px) {
            font-size: 16px;
        }

        &-big {
            font-size: 24px;
            line-height: 1.4;

            @media screen and (max-width: 960px) {
                font-size: 20px;
            }
            @media screen and (max-width: 670px) {
                font-size: 16px;
                line-height: 1.3;
            }
        }

        &-big-more {
            font-size: 26px;
            line-height: 1.4;

            @media screen and (max-width: 960px) {
                font-size: 23px;
            }
            @media screen and (max-width: 670px) {
                font-size: 20px;
                line-height: 1.3;
            }
        }

        p {
            padding-bottom: 20px;

            @media screen and (max-width: 670px) {
                padding-bottom: 15px;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &__block-title {
        padding-bottom: 60px;
        max-width: 700px;
        margin: auto;

        @media screen and (max-width: 960px) {
            padding-bottom: 40px;
        }
        @media screen and (max-width: 670px) {
            padding-bottom: 30px;
        }
    }
    &__subtitle {
        font-weight: 700;
        text-align: center;
    }
    
}

.cultural-new__row--reverse .cultural-new__col--photo {
    order: 1;

    @media screen and (max-width: 670px) {
        order: 0;
    }
}

.promo-slider--cultural {
    padding-bottom: 100px;

    @media screen and (max-width: 960px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 670px) {
        padding-bottom: 50px;
    }
}