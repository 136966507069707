.single-new-new {
    padding: 80px 0 110px;

    @media screen and (max-width: 960px) {
        padding: 75px 0 65px;
    }

    @media screen and (max-width: 670px) {
        padding: 55px 0 50px;
    }

    &__date {
        position : absolute;
        left     : 0;
        bottom   : 53px;
        font-size: 14px;

        @media screen and (max-width: 960px) {
            bottom: 30px;
        }

        @media screen and (max-width: 670px) {
            top   : 41px;
            bottom: auto;
        }
    }

    &__btns-block {
        margin         : 0 -10px;
        display        : flex;
        flex-wrap      : wrap;
        justify-content: flex-end;
    }

    &__btn {
        margin : 0 10px 10px;
        padding: 11px 20px;

        @media screen and (max-width: 670px) {
            width: 100%;
        }

        &--back {
            min-width: 142px;
        }

        &--all-news {
            min-width: 178px;
        }
    }

    &__text-block {
        padding-bottom: 60px;

        @media screen and (max-width: 960px) {
            padding-bottom: 50px;
        }

        @media screen and (max-width: 670px) {
            padding-bottom: 30px;
        }

        h2,
        h3 {
            padding-bottom: 23px;
        }

        h2 {
            font-size: 41px;

            @media screen and (max-width: 960px) {
                font-size: 33px;
            }

            @media screen and (max-width: 670px) {
                font-size: 30px;
            }
        }

        p {
            padding-bottom: 23px;
            font-size     : 20px;
        }

        a {
            // color: #F7931D;
        }

        ul {
            list-style    : none;
            font-size     : 20px;
            padding-bottom: 23px;
        }

        li {
            margin-bottom: 2px;
            position     : relative;
            padding-left : 20px;

            &:before {
                content      : '';
                display      : block;
                width        : 8px;
                height       : 8px;
                border-radius: 50%;
                background   : #000;
                position     : absolute;
                top          : 8px;
                left         : 0;
            }
        }

        img {
            margin-bottom: 23px;
        }
    }

    &__photo-left {
        padding-right: 20px;

        @media screen and (max-width: 670px) {
            padding-right: 0;
            width        : 100%;
        }
    }

    &__columns2 {
        columns   : 2 auto;
        column-gap: 20px;

        @media screen and (max-width: 670px) {
            columns: auto;
        }
    }
}

.single-new-new__promo--photo {
    max-width     : 880px;
    margin        : auto;
    padding-bottom: 37px;

    @media screen and (max-width: 960px) {
        padding-bottom: 15px;
    }

    @media screen and (max-width: 670px) {
        padding-bottom: 20px;
    }

    p {
        font-size : 14px;
        margin-top: -13px;
    }
}

.single-new-new__content--photo {
    padding-top: 37px;
    padding-bottom: 37px;

    @media screen and (max-width: 960px) {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    @media screen and (max-width: 670px) {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    p {
        font-size : 14px;
        margin-top: -13px;
    }
}

.single-new-new__content--table {
    padding-top: 37px;
    padding-bottom: 37px;

    @media screen and (max-width: 960px) {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    @media screen and (max-width: 670px) {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #333;
        margin-bottom: 17px;

        th, td {
            padding: 10px;
            border: 1px solid #333;
            font-size: 20px;

            @media screen and (max-width: 670px) {
                font-size: 14px;
            }
        }
        th {
            font-weight: 700;
            background-color: #f4f4f4;
            text-align: left;
        }
    }
}
.single-new-new__content--table table + p {
    font-size: 14px !important;
}

.single-new-new__text-block--text-page {
    padding-bottom: 0;
}