.our-clients {
    padding: 70px 120px 120px 20px;

    @media screen and (max-width: 1200px)  {
        padding-right: 20px;
    }
    @media screen and (max-width: 960px) {
        padding-top: 0;
        padding-bottom: 80px;
    }

    &__title {
        text-align: center;
        font-size: 36px;
        font-weight: 900;
        padding-bottom: 60px;

        @media screen and (max-width: 670px) {
            font-size: 30px;
            padding-bottom: 50px;
        }
    }
    &__for-slider {
        padding-bottom: 60px;

        @media screen and (max-width: 670px) {
            padding-bottom: 50px;
        }
    }
    &__btns-block {
        text-align: center;
    }
    &__btn {
        min-width: 201px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .slick-track {
        display: flex;
        align-content: center;
        align-items: center;
    }
    &__item {
        text-align: center;
    }
    &__link-item {
        display: inline-block;

        margin: auto;
        webkit-filter: grayscale(1);
        filter: grayscale(1) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        opacity: 1;

        &:hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
    }

}

.our-clients__arrow {
    position       : absolute;
    top            : 0;
    bottom         : 0;
    margin         : auto;
    width          : 21px;
    height         : 36px;
    z-index        : 5;
    background     : url(../images/icons/slider-arrow-white.svg) no-repeat center;
    background-size: contain;

    &--left {
        left: -40px;

        @media screen and (max-width: 670px) {
            left: 0px;
        }
    }

    &--right {
        right    : -40px;
        transform: rotate(180deg);

        @media screen and (max-width: 670px) {
            right: 0px;
        }
    }
}