.promo__text-block:before {
  z-index: 3;
}
.promo__title, .promo__text {
  z-index: 5;
}
.promo__picture {
  display: none;
}
.promo__picture:first-child {
  display: block;
}