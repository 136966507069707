.history-slider__dots {
    position: absolute;
    top: 0;
    left    : 0;
    right   : 0;
    z-index : 700;
    margin   : 0 auto;

    @media screen and (max-width: 1040px) {
        margin: 0 39px;
    }

    @media screen and (max-width: 670px) {
        margin: 0 30px;
    }

    .slick-dots {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: space-between;
        align-content  : center;
        align-items    : center;
        list-style     : none;

        &:before {
            content      : '';
            display      : block;
            width        : 100%;
            height       : 8px;
            background   : #9F9F9F;
            border-radius: 15px;
            position     : absolute;
            left         : 0;
            right        : 0;
            top          : 0;
            bottom       : 0;
            margin       : auto;
        }


        li {
            line-height: 0;
            font-size  : 0;
            flex-grow  : 1;
            position   : relative;
            z-index    : 1;
        }

        button {
            font-size          : 0;
            height             : 8px;
            // background   : #9F9F9F;
            position           : relative;
            opacity            : 1;
            // transition      : all 0.5s;
            width              : 100%;

            &:before {
                content      : '';
                display      : block;
                width        : 100%;
                height       : 8px;
                background   : #9F9F9F;
                border-radius: 15px;
                position     : absolute;
                left         : 0;
                right        : 0;
                top          : 0;
                bottom       : 0;
                margin       : auto;
                // transition: all 0.5s;
            }

            &:hover {
                &:before {
                    height: 14px;
                    top: -3px;
                    background   : #F7931D;
                }
            }

        }

        .slick-active button {
            height       : 8px;
            border-radius: 15px;
            background   : #F7931D;
            opacity      : 1;

            &:before {
                background: #F7931D;
            }
        }
    }
}
.history-slider__arrow {
    background: transparent;
    width: 50%;
    height: 100%;

    &--left {
        left: 0;
    }
    &--right {
        right: 0;
    }

}