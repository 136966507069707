.contacts-form-block {
    &__title-block {
        padding-bottom: 60px;
        width: 100%;

        @media screen and (max-width: 670px) {
            padding-bottom: 30px;
        }
    }
    &__title {
        text-align: center;
        font-size: 28px;
        font-weight: 700;

        @media screen and (max-width: 670px) {
            font-size: 21px;
            text-align: left;
        }
    }
}